import React from "react"
import Hero from "../components/Hero"
import Head from "../components/Head"
import Layout from "../components/Layout"
import "../styles/conocenos.css"

const quienesSomos = props => (
  <>
    <Head
      description="Somos el mejor pollo asado de toda Bogotá desde 1995, conoce todo sobre como llegamos hasta aquí y cual es nuestra historia"
      title="Conocenos - Asadero RoqueFer"
      keywords="restaurantes bogota asadero de pollos"
    />
    <Layout>
      <main>
        <Hero />
        <div className="shippings">
          <h2>Nuestra Historia</h2>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aspernatur
            non vel a quibusdam beatae architecto nihil doloremque quae nam
            laboriosam enim nobis, dolore sunt repellat possimus blanditiis
            soluta voluptas maiores! Lorem ipsum dolor, sit amet consectetur
            adipisicing elit. Quod aspernatur quam voluptate, assumenda nemo
            numquam nobis corporis blanditiis maiores. Nihil aliquid harum odio
            veniam ipsam ullam eius neque quam! Non. Omnis tenetur porro itaque
            natus, consequuntur veritatis commodi quos nihil nobis qui
            asperiores cum et maxime adipisci quas fugiat deserunt ab! Debitis
            vitae modi esse iste ad dolores perferendis fuga. Optio accusamus
            dicta, tenetur sequi, tempora qui molestias omnis vel a deserunt
            minima facilis labore molestiae saepe repudiandae commodi? Beatae
            pariatur explicabo commodi et vel voluptatum soluta doloribus maxime
            porro. Rem voluptatem explicabo magni ad voluptas, porro officiis
            maiores ea adipisci molestias quos quod, reprehenderit eius
            necessitatibus alias officia error veniam tempora omnis tenetur
            commodi illum quidem? Explicabo, error culpa? Repudiandae, dolorem
            aliquid! Fugiat illum, debitis nam molestias aspernatur dignissimos
            enim ullam ea, iste rem veniam. Harum debitis, deserunt, non quos
            aperiam, assumenda consequatur natus vitae ipsam exercitationem sit
            ratione? Voluptatem veritatis explicabo impedit nostrum nisi
            mollitia earum blanditiis dolor, quae in qui eveniet doloribus autem
            eligendi tempore aspernatur labore itaque voluptatum nihil
            voluptate? Ipsum expedita minima odit quas ex? Asperiores deleniti,
            cupiditate porro alias voluptatum ipsam, perspiciatis excepturi
            necessitatibus explicabo dolorum eligendi quibusdam sit esse aliquid
            praesentium sunt unde quisquam odit corporis. Molestias eaque veniam
            eum eos. Doloribus, ad. Ex earum blanditiis doloribus veritatis,
            omnis facilis quod corporis voluptatibus sunt ipsum beatae molestias
            animi, suscipit consequatur eaque neque nesciunt iusto fuga
            praesentium qui. Repellendus ullam cumque blanditiis dicta nemo.
            Odit adipisci mollitia quibusdam dicta impedit! Aperiam,
            exercitationem est cumque optio dignissimos fugiat animi tenetur
            eveniet sed, debitis reiciendis eligendi ea quo? Fugiat, beatae
            temporibus placeat ad ipsam alias blanditiis. Provident laboriosam
            aliquam enim dicta voluptatibus tempora excepturi, cum, voluptas
            quis blanditiis rerum saepe. Hic in id reprehenderit, tempore saepe
            est ratione voluptas maxime ipsa, esse quam, officia neque animi!
            Laborum officia odit quasi consequuntur nisi nobis culpa, sunt
            obcaecati est dicta? Odio pariatur, mollitia nulla soluta dolorum
            dolor veniam totam optio repudiandae reiciendis odit alias,
            corporis, laudantium rem repellat. Eveniet dolore provident, sequi
            ut dolorum voluptatum quisquam, possimus excepturi reiciendis neque
            eos autem culpa atque tempore quidem quae fugiat soluta esse
            repellendus sit consectetur omnis explicabo. Rem, aut tenetur? Non
            odit neque ipsa veritatis sit facilis, fuga voluptatibus assumenda
            sint voluptate, ab quo! Nihil a natus vitae illum explicabo qui
            accusantium dicta excepturi architecto, praesentium eius, minus
            necessitatibus iure. Saepe, cumque error. Illo quis nisi animi modi
            ducimus vel corrupti assumenda, at, incidunt itaque dolorum ipsa,
            accusamus tempora non reprehenderit libero? Voluptatibus suscipit
            debitis laborum blanditiis praesentium deleniti veritatis! Tempora,
            ex. Vero delectus nesciunt aliquam officiis suscipit quas doloremque
            reiciendis corrupti a quidem accusamus labore earum molestiae at
            inventore repellat beatae, sed necessitatibus voluptatum, tenetur
            doloribus provident impedit explicabo! Sed a quidem qui eveniet
            dolorem illum dolore est consequatur accusamus doloremque esse amet
            blanditiis repellat, voluptatem, incidunt nemo error ex facere
            aliquid eaque! Delectus mollitia et facere! Fugit, quam? Veniam
            soluta eveniet eum aspernatur perferendis harum velit autem tenetur
            sapiente, explicabo dolore repellat error, totam sed animi libero
            qui modi facilis necessitatibus molestias rem esse quas. Odit,
            cupiditate iusto? Molestiae qui repellat inventore minus, distinctio
            impedit facere dolorum doloribus labore dolores laborum neque id
            voluptates corporis, ut officiis exercitationem sequi reprehenderit
            voluptatum explicabo provident, corrupti fuga quaerat unde.
            Repudiandae. Excepturi corrupti minus nihil quia commodi ratione
            assumenda ducimus sed aut, aperiam fugiat. Enim molestias veniam
            cupiditate quam officiis id incidunt temporibus voluptas quidem
            repellat, soluta maxime totam similique culpa. Delectus, veniam hic
            quisquam possimus consectetur quaerat nemo laudantium minima et
            facere vero necessitatibus voluptatum quidem quae itaque labore
            voluptatem. Ex, enim maiores quae velit magni sint iusto reiciendis
            dicta. Ducimus tempore repellat consequuntur ex nesciunt,
            reprehenderit odio, fugiat nostrum quasi, placeat assumenda rem
            optio aut. Alias similique mollitia dignissimos quisquam sapiente
            maxime cumque nostrum eveniet. Quo a voluptatibus ad? Quisquam
            corrupti totam illo doloremque quis, veniam, nulla nemo quidem unde
            impedit provident cum ex ad nostrum modi esse repellendus quaerat?
            Ab quae magnam beatae cum aut officia excepturi ea! Sed consequuntur
            aliquam esse magnam magni eaque reprehenderit officiis ducimus atque
            veritatis, repudiandae temporibus alias mollitia distinctio laborum
            nostrum architecto expedita perspiciatis non sapiente ipsam fugit
            dolorem similique nesciunt. Labore. Quasi aut laborum debitis magnam
            impedit cum veniam. Rem eos sequi commodi illum! Cum voluptates
            laboriosam earum omnis expedita asperiores laudantium dicta
            repudiandae ut porro, illo veritatis esse id ipsam? Totam, suscipit
            esse? Delectus magni cumque perspiciatis voluptatum, minus facere
            nesciunt temporibus hic natus explicabo sint nostrum facilis eos,
            dicta ea id praesentium. Nobis consectetur eligendi maxime facere
            natus omnis? Expedita distinctio ipsum magnam perferendis
            repellendus neque facilis impedit voluptatum pariatur, repudiandae
            natus odio aut veniam voluptatibus dignissimos ipsam, reprehenderit
            quia vel voluptatem molestias reiciendis inventore velit? Iusto, eum
            iste. Veritatis non quos, sint suscipit hic vel voluptatibus modi
            nam. Ex quia incidunt necessitatibus amet officia impedit libero
            doloremque dicta ratione! Vel, blanditiis. Quod tenetur veniam
            quisquam ipsum repellat provident. Non distinctio aliquam aspernatur
            nemo consequuntur blanditiis tempora aut animi! Dolor, facilis vitae
            odio similique iure ex vel. Molestiae error debitis in ad doloremque
            nemo similique eligendi praesentium rerum magni. Molestias velit
            consequuntur unde perferendis, quod neque quibusdam! Architecto
            laboriosam et debitis, amet ducimus accusamus magni nobis dolorum
            consectetur quas, quod ex enim dignissimos reprehenderit in quo
            incidunt eveniet. Cum? Recusandae cum et omnis numquam. Vitae
            sapiente iusto obcaecati sed ad aut, blanditiis laboriosam, modi
            praesentium quia sunt, explicabo tempora ipsam tempore! Autem ipsum
            totam, minus nihil possimus iusto nobis?
            <br />
            <br />
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. At nemo
            doloremque praesentium officiis sed veniam vitae unde, deleniti
            labore accusantium veritatis maiores ratione odio harum ad
            aspernatur quos quod animi?
            <br />
            <br />
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et fugit
            eaque vero perspiciatis quod adipisci explicabo amet incidunt earum!
            Dolore vero minus dolor accusamus! Aliquam asperiores quia earum
            soluta quasi!
            <br />
            <br />
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum quo
            voluptas vero, quam inventore consectetur ad asperiores incidunt
            excepturi temporibus! Consequatur, odit explicabo vero cupiditate
            eius tenetur sed impedit fugiat.
          </p>
        </div>
        <div className="video-container">
          <iframe
            className="menu-video"
            title="Video de nuestro menú"
            width="100%"
            // height="315"
            src="https://www.youtube.com/embed/anmOAeSmOp4"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </main>
    </Layout>
  </>
)

export default quienesSomos
