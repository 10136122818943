import React from "react"
import "../styles/hero.css"
import lines from "../../static/assets/icons/RAYAS_ROQUE.png"

const Hero = () => (
  <div className="hero-container">
    <div className="main-hero">
      <p>EL MEJOR POLLO A TU PUERTA</p>
    </div>
    <img className="main-lines" src={lines} alt="Lineas decorativas" />
  </div>
)

export default Hero
